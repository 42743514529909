import React from 'react';
import Layout from '../../components/layout';
import MessageForFailures from '../../components/careers/message-for-failures';
import Eyebrows from '../../components/complements/eyebrows';

const SEO = {
  'title': 'Application Result',
  'author': 'Deutsch LA',
  'keywords': 'Deutsch LA',
};

const ApplicationSuccess: React.FC = () => {
  return (
    <Layout seo={SEO}>
      <div className="max-width mb-20">
        <div className="border-top">
          <Eyebrows
            eyebrow1="Careers"
            eyebrow2=""
            firstEyebrowClass="careers-eyebrow-text-format orange-link"
            refEyebrow1="/careers"
          />
        </div>
        <MessageForFailures
          title="Application Submitted"
          message="Thanks for taking the time to apply for our position. We appreciate your interest in working at Deutsch LA.<br /><br />We’re currently in the process of taking applications for this position. We will begin taking interviews in the next two weeks. If you are selected to continue to the interview process, our human resources department will be in contact with you."
        />
      </div>
    </Layout>
  );
};

export default ApplicationSuccess;
