import React, { Component, ReactNode } from 'react';
import { Link } from 'gatsby';

interface Props {
  eyebrow1: string;
  eyebrow2: string;
  className?: string;
  textColor?: string;
  firstEyebrowClass?: string;
  secondEyebrowClass?: string;
  refEyebrow1?: string;
}

class Eyebrows extends Component<Props> {
  render(): ReactNode {
    const containerClass = `eyebrows-container eyebrow items-start ${this.props.className ?? ''} ${
      this.props.textColor ?? ''
    }`;
    const firstEyebrowClass = `left-eyebrow-style font-bold ${this.props.firstEyebrowClass ?? ''}`;
    const secondEyebrowClass = `right-eyebrow-style ${this.props.secondEyebrowClass ?? ''}`;
    const leftEyebrow = this.props.refEyebrow1 ? (
      <div className={firstEyebrowClass}>
        <Link to={this.props.refEyebrow1}>
          <p>{this.props.eyebrow1}</p>
        </Link>
      </div>
    ) : (
      <p className={firstEyebrowClass}>{this.props.eyebrow1}</p>
    );
    return (
      <div className={containerClass}>
        {leftEyebrow}
        <div className={secondEyebrowClass}>
          <p className="self-end">{this.props.eyebrow2}</p>
        </div>
      </div>
    );
  }
}

export default Eyebrows;
