import React, { Component, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  title: string;
  message: string;
}

class MessageForFailures extends Component<Props> {
  render(): ReactNode {
    return (
      <div className="max-width container m-auto padding-lateral tablet:w-10/12">
        <h2
          className={`text-center py-8 px-10 laptop-standard:pt-20 laptop-standard:pb-10
        laptop-standard:px-20 font-bold laptop-standard:leading-15-b
        laptop-standard:tracking-desk desktop-standard:w-11/12 desktop-large:w-9/12 m-auto`}
        >
          {ReactHtmlParser(this.props.title)}
        </h2>
        <div
          className="pt-10 pb-32 container m-auto px-6 tablet:px-16 laptop-standard:px-12
        laptop-large:px-20 desktop-large:px-64"
        >
          <p className="laptop-standard:text-4lg laptop-standard:leading-7">
            {ReactHtmlParser(this.props.message)}
          </p>
        </div>
      </div>
    );
  }
}

export default MessageForFailures;
